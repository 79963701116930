import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import SEO from "../components/seo";

import {
  Button,
  Hero,
  ReasonCard,
  // SecondaryHeader,
  SectionHeader,
  Link,
} from "../components/common";

import Love from "../assets/images/vectors/love.svg";
import Stars from "../assets/images/vectors/stars.svg";
import Arrow from "../assets/images/vectors/arrow.svg";
import Splash from "../assets/images/vectors/spalsh.svg";
import creativeMarket from "../assets/images/vectors/creative-market.svg";
import Stats from "../assets/images/vectors/stats.svg";
import ui8 from "../assets/images/vectors/ui8.svg";
import envatoElements from "../assets/images/vectors/envato-elements.svg";

import "./why-us.css";
import { getStaticSEOImage } from "../helpers";

function WhyUsPage({
  pageContext: { premiumProductsCount, freeProductsCount },
  location,
}) {
  return (
    <Layout>
      <SEO
        keywords={[`vackground`, `pattern`, `background`, `design`]}
        title="Why US"
        image={getStaticSEOImage("why-us")}
        path={location?.pathname}
      />
      <Helmet>
        <script
          async
          src="https://platform.twitter.com/widgets.js"
          charSet="utf-8"
        />
      </Helmet>
      <section className="md:mt-24 mt-16 lg:mb-40 mb-48">
        <Hero
          subTitle="Why Us?"
          title={
            <>
              We Love Your Success Also,
              <br />
              Not Just The Money!
            </>
          }
          description="Yes you read that right. Everyone needs some kind of support whether they have money or not while building their dream project, that is why we do things such as..."
        />
      </section>
      <section className="dev-vg-why-us__artwork relative mb-16">
        <span className="hidden lg:inline-block">
          <img
            className="absolute"
            style={{ width: 96, top: -100, left: 360 }}
            alt="love"
            src={Love}
          />
        </span>
        <span className="hidden lg:inline-block">
          <img
            className="absolute"
            style={{ width: 96, top: 170, right: 490 }}
            alt="stars"
            src={Stars}
          />
        </span>
        <div className="grid md:grid-cols-2 grid-cols-1 px-4 lg:gap-x-44 gap-x-8 lg:gap-y-24 gap-y-36">
          <ReasonCard
            no={1}
            title="Free Sample of Our Premium Graphic Products"
            description={
              <>
                Most of the Vackground product has a free download sample with
                unlimited use of personal and commercial{" "}
                <Link className="text-brand-primary underline" to="/license">
                  license
                </Link>
                .
              </>
            }
          />
          <ReasonCard
            no={2}
            title="Money Back Guarantee Even After ‘Try Before Buy’"
            description={
              <>
                Try the free sample version before you buy, still you will be
                covered with a 60 days{" "}
                <Link className="text-brand-primary underline" to="/refund">
                  refund policy
                </Link>{" "}
                if you buy after trying and not satisfied at all.
              </>
            }
          />
          <ReasonCard
            no={3}
            title="Free Version of Our All Premium Products"
            description="We treat and help all of our customers equally and try to provide support via email, messenger, instagram, twitter and more except phone call."
          />
          <ReasonCard
            no={4}
            title="Free Version of Our All Premium Products"
            description={
              <>
                Free online tools such as{" "}
                <Link
                  target="__blank"
                  rel="noopener noreferrer"
                  className="text-brand-primary underline"
                  href="https://gradienta.io"
                >
                  Gradienta
                </Link>{" "}
                will speed up your project rapidly and make it shine. We also
                have some upcoming tools to speed up your business.
              </>
            }
          />
        </div>
      </section>
      {/* <section className="mb-32">
        <div>
          <SecondaryHeader
            title={
              <span className="relative block mt-24">
                <span
                  style={{
                    top: "-50%",
                    lineHeight: "105px",
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                  className="gradient-text absolute text-7xl"
                >
                  &
                </span>
                Don’t Just Believe
                <br />
                See What People Says About Us
              </span>
            }
          />
        </div>
        <div className="grid grid-cols-3 gap-x-8 gap-y-4">
          <div>
            <blockquote className="twitter-tweet">
              <p lang="bn" dir="ltr">
                Viva Honduras :)
                <br />
                <br />
                জিতুক আর না জিতুক আমরা হন্ডুরাস থেকে ভালো খেলা আশা করছি :){" "}
                <br />
                We are হন্ডুরাস সমর্থক পার্টি অফ...{" "}
                <a href="http://t.co/fs378yssn5">http://t.co/fs378yssn5</a>
              </p>
              &mdash; Zonayed Ahmed (@zonayedpca){" "}
              <a href="https://twitter.com/zonayedpca/status/478249858823110656?ref_src=twsrc%5Etfw">
                June 15, 2014
              </a>
            </blockquote>
          </div>
          <div>
            <blockquote className="twitter-tweet">
              <p lang="bn" dir="ltr">
                Viva Honduras :)
                <br />
                <br />
                জিতুক আর না জিতুক আমরা হন্ডুরাস থেকে ভালো খেলা আশা করছি :){" "}
                <br />
                We are হন্ডুরাস সমর্থক পার্টি অফ...{" "}
                <a href="http://t.co/fs378yssn5">http://t.co/fs378yssn5</a>
              </p>
              &mdash; Zonayed Ahmed (@zonayedpca){" "}
              <a href="https://twitter.com/zonayedpca/status/478249858823110656?ref_src=twsrc%5Etfw">
                June 15, 2014
              </a>
            </blockquote>
          </div>
          <div>
            <blockquote className="twitter-tweet">
              <p lang="bn" dir="ltr">
                Viva Honduras :)
                <br />
                <br />
                জিতুক আর না জিতুক আমরা হন্ডুরাস থেকে ভালো খেলা আশা করছি :){" "}
                <br />
                We are হন্ডুরাস সমর্থক পার্টি অফ...{" "}
                <a href="http://t.co/fs378yssn5">http://t.co/fs378yssn5</a>
              </p>
              &mdash; Zonayed Ahmed (@zonayedpca){" "}
              <a href="https://twitter.com/zonayedpca/status/478249858823110656?ref_src=twsrc%5Etfw">
                June 15, 2014
              </a>
            </blockquote>
          </div>
          <div>
            <blockquote className="twitter-tweet">
              <p lang="bn" dir="ltr">
                Viva Honduras :)
                <br />
                <br />
                জিতুক আর না জিতুক আমরা হন্ডুরাস থেকে ভালো খেলা আশা করছি :){" "}
                <br />
                We are হন্ডুরাস সমর্থক পার্টি অফ...{" "}
                <a href="http://t.co/fs378yssn5">http://t.co/fs378yssn5</a>
              </p>
              &mdash; Zonayed Ahmed (@zonayedpca){" "}
              <a href="https://twitter.com/zonayedpca/status/478249858823110656?ref_src=twsrc%5Etfw">
                June 15, 2014
              </a>
            </blockquote>
          </div>
          <div>
            <blockquote className="twitter-tweet">
              <p lang="bn" dir="ltr">
                Viva Honduras :)
                <br />
                <br />
                জিতুক আর না জিতুক আমরা হন্ডুরাস থেকে ভালো খেলা আশা করছি :){" "}
                <br />
                We are হন্ডুরাস সমর্থক পার্টি অফ...{" "}
                <a href="http://t.co/fs378yssn5">http://t.co/fs378yssn5</a>
              </p>
              &mdash; Zonayed Ahmed (@zonayedpca){" "}
              <a href="https://twitter.com/zonayedpca/status/478249858823110656?ref_src=twsrc%5Etfw">
                June 15, 2014
              </a>
            </blockquote>
          </div>
          <div>
            <blockquote className="twitter-tweet">
              <p lang="bn" dir="ltr">
                Viva Honduras :)
                <br />
                <br />
                জিতুক আর না জিতুক আমরা হন্ডুরাস থেকে ভালো খেলা আশা করছি :){" "}
                <br />
                We are হন্ডুরাস সমর্থক পার্টি অফ...{" "}
                <a href="http://t.co/fs378yssn5">http://t.co/fs378yssn5</a>
              </p>
              &mdash; Zonayed Ahmed (@zonayedpca){" "}
              <a href="https://twitter.com/zonayedpca/status/478249858823110656?ref_src=twsrc%5Etfw">
                June 15, 2014
              </a>
            </blockquote>
          </div>
        </div>
      </section> */}
      <section className="mb-32">
        <div className="text-center mb-8">
          <SectionHeader
            art
            artVector={Arrow}
            title={
              <>
                Find & Follow Us
                <br />
                On Other Graphic Marketplaces
              </>
            }
            description="We are available on other platforms too. You can follow us or get updates from these platform. However, we sell different products on different market as they have different audiences as well"
          />
        </div>
        <div className="grid lg:grid-cols-3 grid-cols-1 gap-y-2 lg:gap-y-0 lg:px-32 px-0">
          <div className="text-center">
            <div className="hidden lg:block">
              <img
                style={{ height: 48 }}
                className="mx-auto"
                alt="Creative Market"
                src={creativeMarket}
              />
            </div>
            <div className="flex items-center justify-center mt-4">
              <Link
                className="pr-3 text-brand-primary no-underline"
                to="/category/backgrounds"
              >
                <strong className="text-brand-primary">Creative Market</strong>{" "}
                (Vackground)
              </Link>
              <i className="vg-arrow-right" />
            </div>
          </div>
          <div className="text-center">
            <div className="hidden lg:block">
              <img
                style={{ height: 48 }}
                className="mx-auto"
                alt="UI8"
                src={ui8}
              />
            </div>
            <div className="flex items-center justify-center text-brand-primary mt-4">
              <Link
                className="pr-3 text-brand-primary no-underline"
                to="/category/backgrounds"
              >
                <strong className="text-brand-primary">UI8</strong> (Vackground)
              </Link>
              <i className="vg-arrow-right" />
            </div>
          </div>
          <div className="text-center">
            <div className="hidden lg:block">
              <img
                style={{ height: 48, width: 180 }}
                className="mx-auto"
                alt="Envato Elements"
                src={envatoElements}
              />
            </div>
            <div className="flex items-center justify-center text-brand-primary mt-4">
              <Link
                className="pr-3 text-brand-primary no-underline"
                to="/category/backgrounds"
              >
                <strong className="text-brand-primary">Envato Elements</strong>{" "}
                (Vackground)
              </Link>
              <i className="vg-arrow-right" />
            </div>
          </div>
        </div>
      </section>
      <section className="mb-32">
        <div className="text-center mb-12">
          <SectionHeader
            art
            artVector={Stats}
            title={
              <>
                Statistics That Means
                <br />
                You Are in A Good Company
              </>
            }
            description={
              <>
                With over 200K yearly website pageviews, you can feel comfort
                about having a good company beside you. Also, we have made a lot
                of statistics public so you can{" "}
                <Link className="text-brand-primary underline" to="/">
                  check anytime!
                </Link>
              </>
            }
          />
        </div>
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-8 gap-6 px-4">
          <div className="px-8 py-7 bg-background-white text-center rounded-xl text-ui-gray-plus-4">
            <h2 className="font-bold lg:text-4xl text-2.25xl text-ui-gray-plus-4 lg:leading-14 leading-9 tracking-tightty">
              {premiumProductsCount - 1}+
            </h2>
            <h5 className="font-bold text-ui-gray-plus-3 lg:text-pre-lg text-lg lg:leading-7 leading-8 lg:tracking-tightsy tracking-tightie">
              Premium Graphics
            </h5>
          </div>
          <div className="px-8 py-7 bg-background-white text-center rounded-xl text-ui-gray-plus-4">
            <h2 className="font-bold lg:text-4xl text-2.25xl text-ui-gray-plus-4 lg:leading-14 leading-9 tracking-tightty">
              {freeProductsCount - 1}+
            </h2>
            <h5 className="font-bold text-ui-gray-plus-3 lg:text-pre-lg text-lg lg:leading-7 leading-8 lg:tracking-tightsy tracking-tightie">
              Graphic Freebies
            </h5>
          </div>
          <div className="px-8 py-7 bg-background-white text-center rounded-xl text-ui-gray-plus-4">
            <h2 className="font-bold lg:text-4xl text-2.25xl text-ui-gray-plus-4 lg:leading-14 leading-9 tracking-tightty">
              106K
            </h2>
            <h5 className="font-bold text-ui-gray-plus-3 lg:text-pre-lg text-lg lg:leading-7 leading-8 lg:tracking-tightsy tracking-tightie">
              Email Subscribers
            </h5>
          </div>
          <div className="px-8 py-7 bg-background-white text-center rounded-xl text-ui-gray-plus-4">
            <h2 className="font-bold lg:text-4xl text-2.25xl text-ui-gray-plus-4 lg:leading-14 leading-9 tracking-tightty">
              4
            </h2>
            <h5 className="font-bold text-ui-gray-plus-3 lg:text-pre-lg text-lg lg:leading-7 leading-8 lg:tracking-tightsy tracking-tightie">
              Free Design Tools
            </h5>
          </div>
          <div className="px-8 py-7 bg-background-white text-center rounded-xl text-ui-gray-plus-4">
            <h2 className="font-bold lg:text-4xl text-2.25xl text-ui-gray-plus-4 lg:leading-14 leading-9 tracking-tightty">
              13
            </h2>
            <h5 className="font-bold text-ui-gray-plus-3 lg:text-pre-lg text-lg lg:leading-7 leading-8 lg:tracking-tightsy tracking-tightie">
              Sales Made in Envato
            </h5>
          </div>
          <div className="px-8 py-7 bg-background-white text-center rounded-xl text-ui-gray-plus-4">
            <h2 className="font-bold lg:text-4xl text-2.25xl text-ui-gray-plus-4 lg:leading-14 leading-9 tracking-tightty">
              6K+
            </h2>
            <h5 className="font-bold text-ui-gray-plus-3 lg:text-pre-lg text-lg lg:leading-7 leading-8 lg:tracking-tightsy tracking-tightie">
              Graphic Freebies
            </h5>
          </div>
          <div className="px-8 py-7 bg-background-white text-center rounded-xl text-ui-gray-plus-4">
            <h2 className="font-bold lg:text-4xl text-2.25xl text-ui-gray-plus-4 lg:leading-14 leading-9 tracking-tightty">
              370+
            </h2>
            <h5 className="font-bold text-ui-gray-plus-3 lg:text-pre-lg text-lg lg:leading-7 leading-8 lg:tracking-tightsy tracking-tightie">
              Ratings (4.6 Stars)
            </h5>
          </div>
          <div className="px-8 py-7 bg-background-white text-center rounded-xl text-ui-gray-plus-4">
            <h2 className="font-bold lg:text-4xl text-2.25xl text-ui-gray-plus-4 lg:leading-14 leading-9 tracking-tightty">
              8
            </h2>
            <h5 className="font-bold text-ui-gray-plus-3 lg:text-pre-lg text-lg lg:leading-7 leading-8 lg:tracking-tightsy tracking-tightie">
              Events Sponsored
            </h5>
          </div>
        </div>
      </section>
      <section className="mb-24">
        <div className="mb-16">
          <SectionHeader
            art
            artVector={Splash}
            title={
              <>
                Show, Don’t Tell
                <br />
                Grow Your Business Visually!
              </>
            }
          />
        </div>
        <div className="flex items-center justify-center">
          <Button hasMoustache icon="vg-arrow-right" text="Shop Now" />
        </div>
      </section>
    </Layout>
  );
}

WhyUsPage.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

export default WhyUsPage;
